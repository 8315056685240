import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";


function IndexPage() {
  return (

    <Layout>
      <SEO
        title="Simplexe – Des réductions inédites autour de vous"
        keywords={[`Simplexe`, `mobile app`, `application mobile`, `android`, `iOS`, `discount`, `réduction`, `coupon`, `AR`, `shopping`, `paiement`, `géolocalisation`,]}
      />
    </Layout>
  );
}

export default IndexPage;
